import { GRADO_ANOMALIA } from '@/utils/consts'

export default {
  selectGradosAnomaliaIncorrectos (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.grado_anomalia)
      .where(Vue.$offline.db.op.and(
        tables.grado_anomalia.estado.gt(0),
        tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.incorrecto),
      ))
      .exec()
  },
  selectTanomaliaChecklist (Vue, idpreguntaChecklist) {
    const tables = Vue.$offline.db.tables
    const tanomaliaChecklist = Vue.$offline.tanomaliaChecklist
    return Vue.$offline.db
      .select()
      .from(tables.tanomalia_checklist)
      .innerJoin(tanomaliaChecklist.dummyTable, tanomaliaChecklist.pk.eq(tanomaliaChecklist.dummyPk))
      .where(Vue.$offline.db.op.and(
        tables.tanomalia_checklist.estado.gt(0),
        tables.tanomalia_checklist.idpregunta_checklist.eq(idpreguntaChecklist)
      ))
      .orderBy(tables.tanomalia_checklist.descripcion)
      .exec()
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.tanomaliaChecklistOt.row(id)).tanomalia_checklist_ot
  },
  selectAccionesCorrectoras(Vue, idtanomaliaChecklist) {
    const tables = Vue.$offline.db.tables
    const accionCorrectora = Vue.$offline.accionCorrectora
    return Vue.$offline.db
      .select()
      .from(tables.accion_correctora)
      .innerJoin(accionCorrectora.dummyTable, accionCorrectora.pk.eq(accionCorrectora.dummyPk))
      .where(Vue.$offline.db.op.and(
        tables.accion_correctora.estado.gt(0),
        tables.accion_correctora.idtanomalia_checklist.eq(idtanomaliaChecklist)
      ))
      .exec()
  }
}
