<template>
  <b10-base>
    <ValidationProvider
      v-if="tanomaliasChecklist.length > 0 || !hasAnomaliaTextoLibre"
      v-slot="{ errors }"
      name="idtanomalia_checklist"
      :rules="formRules.idtanomalia_checklist"
    >
      <b10-autocomplete
        v-model="form.idtanomalia_checklist"
        :items="tanomaliasChecklist"
        item-value="tanomalia_checklist.idtanomalia_checklist"
        item-text="tanomalia_checklist.descripcion"
        label="Tipo de anomalía"
        clearable
        :error-messages="errors"
        @change="anomaliaSelected(form.idtanomalia_checklist)"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap"
            >
              {{ slotProps.item.tanomalia_checklist.descripcion }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <ValidationProvider
      v-if="hasAnomaliaTextoLibre"
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        flat
        label="Descripción"
        :disabled="typeof(form.idtanomalia_checklist) !== 'undefined' && form.idtanomalia_checklist !== null"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-if="hasAgregarADefinicionChecklist"
      v-slot="{ errors }"
      name="agregaradefinicioncheck"
      :rules="formRules.agregaradefinicioncheck"
    >
      <v-checkbox
        v-model="form.agregaradefinicioncheck"
        :disabled="(typeof(form.idtanomalia_checklist) !== 'undefined' && form.idtanomalia_checklist !== null)"
        label="Crear como tipo de anomalía genérica"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-if="hasAnomaliaTextoLibre"
      v-model="form.descripcion_ampliada"
      label="Descripción ampliada"
    />
    <b10-textarea
      v-model="form.notas_internas"
      label="Notas internas"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="idgrado_anomalia"
      :rules="formRules.idgrado_anomalia"
    >
      <b10-autocomplete
        v-model="form.idgrado_anomalia"
        :items="gradosAnomalia"
        item-value="idgrado_anomalia"
        item-text="descripcion"
        label="Grado de anomalía"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-checkbox
      v-model="form.mejora"
      label="Es una mejora"
    />
    <v-checkbox
      v-model="form.subsanado"
      label="Fue subsanada durante la revisión"
    />
    <ValidationProvider
      v-if="isInsert && idtfichero && hasInsertAnomaliaAdjuntoPerm"
      v-slot="{ errors }"
      name="files"
      :rules="formRules.files"
    >
      <v-file-input
        v-model="form.files"
        label="Ficheros"
        show-size
        prepend-icon="attach_file"
        chips
        multiple
        :error-messages="errors"
        @change="filesChange"
      />
    </ValidationProvider>
    <template
      v-if="accionesCorrectoras.length > 0"
    >
      <b10-subheader
        label="Acciones correctoras"
        :icon="$vuetify.icons.values.accionCorrectora"
      />

      <v-list
        class="ml-n4"
      >
        <v-list-item
          v-for="(accion, key) in accionesCorrectoras"
          :key="key"
        >
          <v-list-item-content>
            <v-list-item-title
              class="break-word"
            >
              <strong>Incluir:</strong> {{ accion.title }}<strong>:</strong>
            </v-list-item-title>
            <v-card-subtitle
              class="py-1 ml-n4"
            >
              <div class="d-flex justify-start">
                <v-btn-toggle
                  v-model="form[accion.fieldnameAccionesARealizar]"
                  color="primary"
                  mandatory
                  borderless
                  size="small"
                  class="v-size--small"
                >
                  <v-btn
                    v-for="item in accionesARealizar.values"
                    :key="item"
                    :value="item"
                    color="white"
                    density="comfortable"
                    size="small"
                    class="v-size--small"
                    @click="clickAccionesARealizar(item, accion)"
                  >
                    {{ accionesARealizar.descripciones[item] }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-card-subtitle>
            <v-chip
              v-if="form[accion.fieldnameMensajeFacturacionAccionCorrectora]"
              color="info"
            >
              {{ form[accion.fieldnameMensajeFacturacionAccionCorrectora] }}
            </v-chip>
            <v-divider class="mt-3" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './AnomaliaFormData'
import _ from '@/utils/lodash'
import { PARAMETRO, ACCIONES_A_REALIZAR } from '@/utils/consts'
import  { extend } from 'vee-validate'
import { size as fileSizeValidation } from 'vee-validate/dist/rules'

export default {
  mixins: [formMixin],
  props: {
    idpreguntaChecklistOt: {
      type: [Number, String],
      required: true
    },
    idtfichero: {
      type: Number,
      default: 0,
    },
    idgradoAnomalia: {
      type: Number,
      default: 0,
    },
    hasInsertAnomaliaAdjuntoPerm: {
      type: Boolean,
      default: true,
    },
    hasAgregarADefinicionChecklist: {
      type: Boolean,
      default: true
    },
    hasAnomaliaTextoLibre: {
      type: Boolean,
      default: true
    },
    materialAfectado: {
      type: Object,
      default: () => {},
    },
    idtanomaliaChecklist: {
      type: [Number, String],
      default: '',
    },
  },
  data () {
    return {
      form: {
        idtanomalia_checklist: null,
        descripcion: '',
        descripcion_ampliada: '',
        idgrado_anomalia: null,
        mejora: false,
        subsanado: false,
        agregaradefinicioncheck: true,
        files: [],
        notas_internas: '',
      },
      formRules: {
        descripcion: { required: true },
        idgrado_anomalia: { required: true },
        files: { tamanioFicheroAdjunto: true },
        idtanomalia_checklist: { required: !this.hasAnomaliaTextoLibre },
      },
      gradosAnomalia: [],
      tanomaliasChecklist: [],
      tamanioMaxAdjuntoMB: 0,
      valorParametroAgregaraDefinicioCheck: true,
      accionesCorrectoras: [],
      realizarAccionCorrectora: [],
      accionesARealizar: ACCIONES_A_REALIZAR,
    }
  },
  async created () {
    const maxAdjuntoMB = await this.$offline.parametro.valorParametro(PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB)
    if (maxAdjuntoMB) {
      this.tamanioMaxAdjuntoMB = parseInt(maxAdjuntoMB)
    }
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      }
    })
    const pregunta = await this.$offline.preguntaChecklistOt.row(this.idpreguntaChecklistOt)
    this.tanomaliasChecklist = await Data.selectTanomaliaChecklist(
      this, pregunta.pregunta_checklist_ot.idpregunta_checklist
    )
    this.gradosAnomalia = await Data.selectGradosAnomaliaIncorrectos(this)
    if (this.isInsert) {
      if (this.idgradoAnomalia) {
        this.$set(this.form, 'idgrado_anomalia', this.idgradoAnomalia)
      }
      if (this.idtanomaliaChecklist) {
        this.$set(this.form, 'idtanomalia_checklist', this.idtanomaliaChecklist)
        // forzar evento
        this.anomaliaSelected(this.idtanomaliaChecklist)
      }
    }
    this.valorParametroAgregaraDefinicioCheck = (
      await this.$offline.parametro.valorParametro(
        PARAMETRO.parametros.AGREGAR_ANOMALIA_A_DEFINICION_CHECKLIST)
    ) === '1'
    this.form.agregaradefinicioncheck = this.valorParametroAgregaraDefinicioCheck
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'idtanomalia_checklist', formData.idtanomalia_checklist)
      this.$set(this.form, 'descripcion', formData.descripcion)
      this.$set(this.form, 'descripcion_ampliada', formData.descripcion_ampliada)
      this.$set(this.form, 'idgrado_anomalia', formData.idgrado_anomalia)
      this.$set(this.form, 'mejora', formData.mejora)
      this.$set(this.form, 'subsanado', formData.subsanado)
      this.$set(this.form, 'agregaradefinicioncheck', formData.agregaradefinicioncheck)
      this.$set(this.form, 'notas_internas', formData.notas_internas)
    },
    async cleanAccionesCorrectoras () {
      this.accionesCorrectoras = []
      for (const field in this.form) {
        if (field.includes('idaccion_correctora')) {
          delete this.form[field]
        }
      }
    },
    async anomaliaSelected (idtanomaliaChecklist) {
      await this.cleanAccionesCorrectoras()
      if (idtanomaliaChecklist) {
        const anomaliaSeleccionada = _.filter(this.tanomaliasChecklist, (item) => {
          return (item.tanomalia_checklist.idtanomalia_checklist === idtanomaliaChecklist)
        })
        this.$set(this.form, 'descripcion', anomaliaSeleccionada[0].tanomalia_checklist.descripcion)
        this.$set(this.form, 'idgrado_anomalia', anomaliaSeleccionada[0].tanomalia_checklist.idgrado_anomalia)
        this.$set(this.form, 'mejora', anomaliaSeleccionada[0].tanomalia_checklist.mejora)
        this.$set(this.form, 'agregaradefinicioncheck', false)
        // Busco si inserta acciones o si inserta artículo
        const accionesCorrectoras = await Data.selectAccionesCorrectoras(this, idtanomaliaChecklist)
        for (const accion of accionesCorrectoras) {
          const fieldname = `idaccion_correctora_${accion.accion_correctora.idaccion_correctora}`
          const fieldnameAccionesARealizar = `${this.accionesARealizar.fieldname}${accion.accion_correctora.idaccion_correctora}`
          const fieldnameMensajeFacturacionAccionCorrectora = `mensaje_idaccion_correctora_${accion.accion_correctora.idaccion_correctora}`
          this.accionesCorrectoras.push({
            title: accion.accion_correctora.descripcion,
            fieldname,
            key: accion.accion_correctora.idaccion_correctora,
            fieldnameAccionesARealizar,
            fieldnameMensajeFacturacionAccionCorrectora,
          })
          this.$set(this.form, fieldname, false)
          this.$set(this.form, fieldnameAccionesARealizar, this.accionesARealizar.values.no_incluir)
          this.$set(this.form, fieldnameMensajeFacturacionAccionCorrectora, '')
        }
      } else {
        this.$set(this.form, 'descripcion', '')
        this.$set(this.form, 'idgrado_anomalia', null)
        this.$set(this.form, 'mejora', false)
        this.$set(this.form, 'agregaradefinicioncheck', this.valorParametroAgregaraDefinicioCheck)
      }
    },
    filesChange (files) {
      this.$set(this.form, 'files', files)
    },
    async clickCheckAccionCorrectora (value, fieldnameRealizar) {
      if (!value && this.form[fieldnameRealizar]) {
        this.$set(this.form, fieldnameAccionesARealizar, this.accionesARealizar.values.no_incluir)
      }
    },
    async clickAccionesARealizar (value, accion) {
      const accionCorrectora = await this.$offline.accionCorrectora.row(accion.key)
      const fieldnameMensajeFacturacionAccionCorrectora = `mensaje_idaccion_correctora_${accion.key}`
      if (value == this.accionesARealizar.values.incluir_y_realizar) {
        if (!this.form.subsanado) {
          this.$set(this.form, 'subsanado', true)
        }
        const MensajeFacturacionAccionCorrectora =  await this.$offline.accionCorrectora.MensajeFacturacionAccionCorrectora(
          this, accionCorrectora, this.materialAfectado
        )
        this.$set(this.form, fieldnameMensajeFacturacionAccionCorrectora, MensajeFacturacionAccionCorrectora)
      } else {
        this.$set(this.form, fieldnameMensajeFacturacionAccionCorrectora, '')
      }
    },
  }
}
</script>
